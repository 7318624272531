<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="bDialogGenerateReport"
    width="600px"
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-generate-report">
          <p class="title-generate-report ">
            Generación de documento de reporte
          </p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-description-generate-report">
          <p class="description-generate-report">
            Para continuar con la generación del documento es necesario
            especificar un periodo de tiempo, estas son las opciones
            disponibles.
          </p>
        </div>

        <div class="content-filter-generate-report-all">
          <p class="txt-filter-generate-report">
            Generar documento de reporte de acuerdo a:
          </p>
          <div
            v-for="(item, i) in aFilter"
            :key="i"
            class="content-filter-generate-report"
          >
            <div class="content-checkbox-filter-generate-report">
              <v-checkbox
                class="global-checkbox checkbox-filter-generte-report"
                color="var(--primary-color-text-yellow)"
                v-model="sFilter"
                :value="item.sValue"
              >
                <template v-slot:label>
                  <div v-if="item.sValue !== 6">
                    <p class="question-generate-report">
                      {{ item.sName }}
                    </p>
                  </div>
                </template>
              </v-checkbox>
              <div
                v-if="item.sValue === 6"
                class="content-date-picker-filter-range"
              >
                <v-menu
                  ref="menu"
                  v-model="bMenu"
                  :close-on-content-click="false"
                  :return-value.sync="aDates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="aDates"
                      :disabled="item.sValue !== sFilter"
                      outlined
                      chips
                      clearable
                      class="global-text-field global-text-field-date"
                      label="Seleccionar rango de fechas"
                      placeholder="Seleccionar rango de fechas..."
                      color="var(--primary-color-border-input)"
                      background-color="var(--primary-color-menu)"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    >
                      <template slot="append">
                        <v-icon
                          :color="
                            item.sValue !== sFilter
                              ? 'grey'
                              : 'var(--primary-color-text)'
                          "
                          @click="
                            item.sValue !== sFilter ? null : (bMenu = true)
                          "
                          >mdi-calendar-blank</v-icon
                        >
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="aDates"
                    :max="sMaxDate"
                    range
                    no-title
                    locale="es"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="bMenu = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!bAddDates"
                      text
                      color="primary"
                      @click="save(aDates)"
                    >
                      <span :class="bAddDates ? 'color-yellow-global' : ''"
                        >Guardar</span
                      >
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>

            <v-icon
              :color="
                item.sValue === sFilter
                  ? 'var(--primary-color-text-green)'
                  : 'var(--primary-color-btn-neutral)'
              "
              >mdi-check-circle</v-icon
            >
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              :disabled="!sFilter"
              :loading="bLoading"
              class="global-btn-primary"
              @click="setGenerateReport()"
            >
              Generar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogGenerateReport: Boolean,
    oDialogGenerateReport: Object,
    sExcelType:String
  },
  data() {
    return {
      screenWidth: 0,
      checkbox: false,
      bAdminGlobal: false,
      bLoading: false,
      sReport: "",
      aFilter: [
        {
          sName: "Al día de hoy",
          sValue: 1,
        },
        {
          sName: "Los últimos 7 dias",
          sValue: 2,
        },
        {
          sName: "El presente mes",
          sValue: 3,
        },
        {
          sName: "El presente mes y el pasado",
          sValue: 4,
        },
        {
          sName: "Los últimos 6 meses",
          sValue: 5,
        },
        {
          sName: "Rango de fecha especifico",
          sValue: 6,
          aDateRange: [],
        },
      ],
      sFilter: "",
      itemGenerateReport: {},

      bMenu: false,
      bAddDates: false,
      aDates: [],
      tStart: "",
      tEnd: "",
      sMaxDate: new Date().toISOString().slice(0, 10),
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 70;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    getPayload(sTypeFilter) {
      if (sTypeFilter === 6) {
        return {
          tStart: this.tStart,
          tEnd: this.tEnd,
          sExcelType:this.sExcelType
        };
      } else {
        return {
          eRangeType: this.sFilter,
          sExcelType:this.sExcelType
        };
      }
    },
    setGenerateReport() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          const payload = {},
            config = {
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
              params: this.getPayload(this.sFilter),
            };
          DB.get(`${URI}/api/sp/v1/finances/excel`, config, payload)
            .then((response) => {
              this.Success(response.data.message);
              this.bLoading = false;
              this.close();
              window.open(response.data.results,"_blank")
            })
            .catch((error) => {
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    close() {
      this.$emit("setDialogGenerateReport");
      this.sFilter = "";
      this.checkbox = false;
      this.bLoading = false;
    },
    save(aDates) {
      if (this.aDates.length > 1) {
        let fecha_inicial = this.aDates[0];
        let fecha_final = this.aDates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.aDates[1];
          this.aDates = [];
          this.aDates[0] = lastDate;
        } else {
          this.$refs.menu[0].save(this.aDates);
          this.tStart = this.aDates[0];
          this.tEnd = this.aDates[1];
        }
      }
    },
  },
  computed: {},
  watch: {
    bDialogGenerateReport() {
      if (this.bDialogGenerateReport) {
        this.itemGenerateReport = this.oDialogGenerateReport;
      }
    },
    aDates() {
      if (this.aDates !== null) {
        if (this.aDates.length > 1) {
          this.bAddDates = true;
          let fecha_inicial = this.aDates[0];
          let fecha_final = this.aDates[1];
          if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
            let lastDate = this.aDates[1];
            this.aDates = [];
            this.aDates[0] = lastDate;
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
      }
    },
    sFilter() {
      this.aDates = [];
    },
  },
};
</script>
<style>
.checkbox-filter-generte-report
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-icon {
  color: var(--primary-color-text) !important;
}
.checkbox-filter-generte-report
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .mdi-checkbox-marked {
  color: var(--primary-color-text-yellow) !important;
}
</style>
<style scoped>
.content-title-generate-report {
  width: 100%;
}
.content-description-generate-report {
  width: 100%;
}
.title-generate-report {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
  word-break: normal;
}
.description-generate-report {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: center;
}

.txt-filter-generate-report {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
}

.content-checkbox-filter-generate-report {
  display: flex;
  align-items: center;
}
.content-date-picker-filter-range {
  width: 300px;
}

.content-filter-generate-report {
  width: 100%;
  justify-self: center;
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  padding: 10px 0px;
}
.checkbox-filter-generte-report
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-icon {
  color: var(--primary-color-text) !important;
}
.question-generate-report {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}
.content-btn-primary {
  width: 100%;
}
.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .title-generate-report {
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
    font-size: 25px;
    text-align: center;
    word-break: normal;
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-filter-generate-report {
    width: 100%;
    justify-self: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 10px !important;
  }
  .content-date-picker-filter-range {
  width: 100%;
}
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
